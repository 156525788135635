<template>
    <div class="table-page">
        <div>
            <div style="float:right">
                <el-button size="mini" @click="getData">刷新</el-button>
            </div>
        <el-breadcrumb separator="/" style="margin-bottom: 20px">
            <el-breadcrumb-item :to="{ path: '/main/dashboard' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>门诊叫号</el-breadcrumb-item>
            <el-breadcrumb-item>就诊队列</el-breadcrumb-item>
        </el-breadcrumb>
        </div>
        <div style="background-color: #eeeeee;display: flex">
            <div style="padding: 10px;flex:1">就诊日期：{{date}}</div>
            <div style="padding: 10px;flex:1">当前诊室：{{room.name}}</div>
            <div style="padding: 10px;flex:1">出诊：{{room.doctor.name}}</div>
        </div>
        <div class="page-main">
            <el-table :data="table.data" style="width: 100%" :row-class-name="tableRowClassName" v-loading="loading">
                <el-table-column prop="time" label="预约时间"></el-table-column>
                <el-table-column prop="department.name" label="科室"></el-table-column>
                <el-table-column prop="patient" label="姓名"></el-table-column>
                <el-table-column prop="age" label="年龄"></el-table-column>
                <el-table-column prop="gender" label="性别"></el-table-column>
                <el-table-column prop="source" label="来源"></el-table-column>
                <el-table-column prop="state" label="状态" :formatter="stateFormatter"></el-table-column>
                <el-table-column align="center" label="操作" width="220">
                    <template slot-scope="scope">
                        <el-button @click="handleUpdate(scope.row)" size="small">叫号</el-button>
                        <el-button @click="handleReset(scope.row)" size="small">重置</el-button>
                        <el-button @click="handleIgnore(scope.row)" size="small">忽略</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--            <el-table stripe :data="table.data" style="width: 100%"-->
            <!--                      height="100%"-->
            <!--                      v-loading="loading" border>-->
            <!--                <el-table-column type="expand">-->
            <!--                    <template slot-scope="props">-->
            <!--                        <div style="margin-left:50px;line-height: 30px;">-->
            <!--                            <div>患者姓名：{{ props.row.patient}}</div>-->
            <!--                            <div>身份证号：{{ props.row.idnum}}</div>-->
            <!--                            <div>联系电话：{{ props.row.phone}}</div>-->
            <!--                            <div>病情简述：{{ props.row.desc}}</div>-->
            <!--                            <div>预约时间：{{ props.row.createAt}}</div>-->
            <!--                        </div>-->
            <!--                    </template>-->
            <!--                </el-table-column>-->
            <!--                <el-table-column align="center"-->
            <!--                                 v-for="(v, idx) in table.columns"-->
            <!--                                 :prop="v.field"-->
            <!--                                 :label="v.title"-->
            <!--                                 :width="v.width"-->
            <!--                                 :formatter="v.formatter"-->
            <!--                                 :key="idx">-->
            <!--                </el-table-column>-->
            <!--                <el-table-column align="center" label="操作" width="150">-->
            <!--                    <template slot-scope="scope">-->
            <!--                        <el-button @click="handleUpdate(scope.row)" size="small">叫号</el-button>-->
            <!--                        <el-button @click="handleReset(scope.row)" size="small">重置</el-button>-->
            <!--                    </template>-->
            <!--                </el-table-column>-->
            <!--            </el-table>-->
        </div>
    </div>
</template>

<script>
    import formatter from "@/data/formatter";

    export default {
        data() {
            let today = new Date()
            // today.setTime(today.getTime()+24*60*60*1000);
            let tomorrow = today.format("yyyy-MM-dd")
            return {
                loading: false,
                room: {doctor: {}},
                date: tomorrow,
                search: {
                    date: tomorrow
                },
                table: {
                    columns: [
                        // {title: '院区', field: 'hospital.name', width: ''},
                        // {title: '科室', field: 'department.name', width: ''},
                        {title: '就诊日期', field: 'date', width: ''},
                        {title: '就诊时间', field: 'time', width: ''},
                        {title: '患者姓名', field: 'patient', width: ''},
                        {title: '年龄', field: 'age', width: ''},
                        {title: '医生姓名', field: 'doctor.name', width: ''},
                        {title: '患者来源', field: 'source', width: ''},
                        {title: '状态', field: 'state', width: '', formatter: formatter.stateFormatter},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 10
                },
            }
        },
        methods: {
            stateFormatter(row) {
                return formatter.stateFormatter(row)
            },
            // eslint-disable-next-line no-unused-vars
            tableRowClassName({row, rowIndex}) {
                console.log(row)
                if (row.state === 5) {
                    return 'warning-row';
                } else if (row.state === 4) {
                    return 'success-row';
                }
                return '';
            },
            handleCurrentChange(e) {
                this.table.page = e
                this.getData()
            },
            handleSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async handleIgnore(row) {
                row.state = 5 // 已叫号 状态码：4
                const resp = await this.$http.put(`/mingde/appoint/${row.id}`, row)
                if (resp.data.code === 200) {
                    this.$message(`${row.patient} 已忽略`)
                    this.getData()
                } else {
                    this.$message.error("叫号失败")
                }
            },
            async handleReset(row) {
                row.state = 0 // 已叫号 状态码：4
                const resp = await this.$http.put(`/mingde/appoint/${row.id}`, row)
                if (resp.data.code === 200) {
                    this.$message(`${row.patient} 状态重置`)
                    this.getData()
                } else {
                    this.$message.error("叫号失败")
                }
            },
            async handleUpdate(row) {
                // this.form = row
                // this.$router.push(`/main/record/form?id=${row.id}`)
                console.log(row)
                row.state = 4 // 已叫号 状态码：4
                const resp = await this.$http.put(`/mingde/appoint/${row.id}`, row)
                if (resp.data.code === 200) {
                    this.$message(`${row.patient} 请到 ${row.department.name} 科就诊`)
                    this.getData()
                } else {
                    this.$message.error("叫号失败")
                }
            },
            async handleDel(scope) {
                this.$refs[`popover-${scope.$index}`].doClose()
                const resp = await this.$http.delete(`/mingde/appoint/${scope.row.id}`)
                if (resp.data.code === 200) {
                    this.$message.success("删除成功")
                    this.getData()
                } else {
                    this.$message.error("删除失败")
                }
            },
            async getData() {
                this.loading = true
                let doctor = this.$store.getters.username
                this.search.plan_todo = 0
                const room = await this.$http.get(`/mingde/room/doctor/?doctor=${doctor}`)
                this.room = room.data.data
                // let params = this.search
                let params = {date: this.date, doctor_id: this.room.doctor.id, room: this.room.id}
                const resp = await this.$http.get('/mingde/appoint/jiaohao/', {params: params})
                console.log(resp.data.data)
                this.table.data = resp.data.data || []
                this.loading = false
            },
            handleSearch() {
                this.table.page = 1
                this.getData()
            },
            todo() {
                this.$message("需对接大屏")
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style>
    .el-table .warning-row {
        background: oldlace;
    }

    .el-table .success-row {
        background: #f0f9eb;
    }
</style>
